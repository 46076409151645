var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',{ref:"layout",on:{"refresh":_vm.refresh},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [_c('b-breadcrumb-item',{attrs:{"text":("Management - " + (_vm.$store.state.settings.app.current.title))}}),_c('b-breadcrumb-item',{attrs:{"active":"","text":"Invoices"}})]},proxy:true},{key:"dropdown-options",fn:function(ref){return [_c('b-dropdown-item',{on:{"click":_vm.refresh}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-rotate-right"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Refresh")])],1)]}},{key:"actions",fn:function(ref){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('invoice-generation-modal'),expression:"'invoice-generation-modal'"}],attrs:{"size":"sm","variant":"primary","disabled":_vm.generation.processing}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-plus"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Generate Invoices")])],1),_c('invoice-generation-modal',{attrs:{"id":"invoice-generation-modal"},on:{"generate":_vm.generateInvoices}})]}},{key:"content",fn:function(ref){
var state = ref.state;
return [_c('table-layout',{ref:"table-layout",attrs:{"items":_vm.table.items,"fields":_vm.table.fields,"filters":_vm.table.filters,"filters-options":{ visible: true, collapsed: false, cols: 3 },"sorting":_vm.table.sorting,"func-delete":_vm.deleteInvoice,"loading":_vm.table.loading,"visible":_vm.table.visible,"export-exclude-fields":[
                      'id',
                      'student.id',
                      'student.school.id',
                      'status' ]},on:{"mounted":function($event){_vm.table = $event},"updated":function($event){_vm.table = $event}},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('overlay-loading',{attrs:{"items":[
          { state: _vm.setting === null, desc: 'Loading Settings' },
          { state: _vm.table.loading, desc: 'Loading Invoices', loaded: _vm.table.items.length },
          { state: state.loading, desc: 'Rendering Template'} ]}})]},proxy:true},{key:"filters",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"status-input"}},[_c('v-select',{staticClass:"w-100 font-small-3",attrs:{"id":"status-input","options":_vm.statusLabels,"reduce":function (option) { return option.value; },"searchable":false,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.table.filters.status.value),callback:function ($$v) {_vm.$set(_vm.table.filters.status, "value", $$v)},expression:"table.filters.status.value"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Accepted","label-for":"accepted-input"}},[_c('v-select',{staticClass:"w-100 font-small-3",attrs:{"id":"accepted-input","options":_vm.options.accepted,"reduce":function (option) { return option.value; },"label":"label","searchable":false,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.table.filters.accepted.value),callback:function ($$v) {_vm.$set(_vm.table.filters.accepted, "value", $$v)},expression:"table.filters.accepted.value"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"School","label-for":"school-input"}},[_c('v-select',{staticClass:"w-100 font-small-3",attrs:{"id":"school-input","options":_vm.options.schools.items,"loading":_vm.options.schools.loading,"reduce":function (option) { return option.id; },"label":"name","filter":_vm.filterSchools,"searchable":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},scopedSlots:_vm._u([{key:"option",fn:function(ref){
        var name = ref.name;
return [_vm._v(" "+_vm._s(name.legal)+" ")]}},{key:"selected-option",fn:function(ref){
        var name = ref.name;
return [_vm._v(" "+_vm._s(name.legal)+" ")]}}],null,true),model:{value:(_vm.table.filters.school.value),callback:function ($$v) {_vm.$set(_vm.table.filters.school, "value", $$v)},expression:"table.filters.school.value"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Ensemble","label-for":"ensemble-input"}},[_c('v-select',{staticClass:"w-100 font-small-3",attrs:{"id":"ensemble-input","options":_vm.options.ensembles.items,"loading":_vm.options.ensembles.loading,"reduce":function (option) { return option.id; },"label":"name","searchable":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.table.filters.ensemble.value),callback:function ($$v) {_vm.$set(_vm.table.filters.ensemble, "value", $$v)},expression:"table.filters.ensemble.value"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Instrument","label-for":"instrument-input"}},[_c('v-select',{staticClass:"w-100 font-small-3",attrs:{"id":"instrument-input","options":_vm.options.instruments.items,"loading":_vm.options.instruments.loading,"reduce":function (option) { return option.id; },"label":"name","searchable":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.table.filters.instrument.value),callback:function ($$v) {_vm.$set(_vm.table.filters.instrument, "value", $$v)},expression:"table.filters.instrument.value"}})],1)],1)],1)]},proxy:true},{key:"cell(student)",fn:function(ref){
        var data = ref.data;
return [_c('b-media',{attrs:{"vertical-align":"center","no-body":""}},[_c('b-media-aside',[_c('b-avatar',{attrs:{"button":"","variant":"primary","size":"2.5em","badge-variant":"primary","badge-offset":"-2px"},on:{"click":data.toggleDetails},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('b-icon',{attrs:{"icon":data.item._showDetails === true ? 'chevron-up' : 'chevron-down'}})]},proxy:true}],null,true)},[_c('font-awesome-icon',{attrs:{"icon":_vm.icon}})],1)],1),_c('b-media-body',{staticClass:"align-self-center"},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{ name: 'management-invoice', params: { id: data.item.id } }}},[_vm._v(" "+_vm._s(data.item.student.name.full)+" ")]),(_vm.hasValue(data, 'item.student.school.name.legal'))?_c('span',{staticClass:"d-block font-small-3"},[_vm._v(" "+_vm._s(data.item.student.school.name.legal)+" ")]):_c('span',{staticClass:"d-block font-small-3 text-danger"},[_vm._v("No School")])],1)],1)]}},{key:"cell(status)",fn:function(ref){
        var data = ref.data;
return _vm._l((data.item.status),function(status){return _c('b-badge',{key:status.text,staticClass:"text-capitalize mr-50",attrs:{"pill":"","variant":status.variant}},[_vm._v(_vm._s(status.text))])})}},{key:"cell(row-options)",fn:function(ref){
        var data = ref.data;
return [_c('b-dropdown-item',{attrs:{"to":{ name: 'management-invoice', params: { id: data.item.id } }}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Details")])],1),_c('can',{attrs:{"do":"delete","on":"management-invoice"}},[_c('b-dropdown-item',{staticClass:"table-row-option-delete",on:{"click":function($event){return _vm.$refs.layout.confirmDelete(data.item, _vm.deleteInvoice, _vm.cascadeConfirmDeleteOptions)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Delete")])],1)],1)]}}],null,true)}),(!_vm.table.visible)?[_c('invoice-generator',{key:_vm.generation.key,attrs:{"configuration":_vm.generation.config,"debug":state.debug},on:{"start":function($event){_vm.generation.processing = true},"complete":function($event){_vm.generation.processing = false},"close":_vm.backToTable}})]:_vm._e()]}},{key:"debug",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('debug',{attrs:{"title":"Table","collapsed":true}},[_vm._v(_vm._s(_vm.table))])],1),_c('b-col',{attrs:{"cols":"4"}},[_c('debug',{attrs:{"title":"Settings","collapsed":true}},[_vm._v(_vm._s(_vm.setting))])],1),_c('b-col',{attrs:{"cols":"4"}},[_c('debug',{attrs:{"title":"Options","collapsed":true}},[_vm._v(_vm._s(_vm.options))])],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }