<template>
  <b-alert show variant="primary" class="d-print-none">
    <b-list-group>
      <b-list-group-item>
        <div class="d-flex justify-content-between align-items-center">
          Invoice Generator
					<b-button v-if="query.completed && creation.completed"
										size="sm"
										class="shadow-none px-0"
										variant="link"
										:disabled="query.processing || creation.processing"
										@click="details.expanded = !details.expanded">
						Show Details
					</b-button>
        </div>
        <div class="my-1">
          <div class="d-flex justify-content-between">

            <small class="mb-25">{{ progressStatusText }}</small>

            <div v-if="query.processing" class="font-small-3">
              <span class="percentage">{{ (query.processed / query.total) * 100 | clamp }}%</span>
            </div>
            <div v-if="creation.processing" class="font-small-3">
              <span>(About {{ creation.expectedDuration | duration(creation.processed / creation.total) }})</span>
              <span class="percentage">{{ (creation.processed / creation.total) * 100 | clamp }}%</span>
            </div>
            <div v-if="query.completed && creation.completed" class="font-small-3">
							<span>Queried: {{ query.items.length }}</span> |
              <span>Created: {{ creation.success }} / {{ creation.processed }}</span>
            </div>
          </div>

          <b-progress :max="100">
            <b-progress-bar :value="queryProgressBarPercentage" :variant="queryProgressBarVariant" :animated="query.processing" />
            <b-progress-bar :value="creationProgressBarPercentage" :variant="creationProgressBarVariant" :animated="creation.processing" />
          </b-progress>
        </div>

        <div v-if="creation.completed">
          <vue-perfect-scrollbar ref="processed-scrollbar" :settings="{ maxScrollbarLength: 150,wheelPropagation: false }" class="ps-processed">
            <b-collapse id="ui.processed.expanded" v-model="details.expanded">
              <b-list-group v-if="query.total > 0" flush class="pt-50">
                <b-list-group-item v-for="(item, index) in query.items" :key="index" class="px-0 py-25">
                  <div class="d-flex justify-content-between align-items-start">
                    <div>
                      <small class="text-capitalize font-weight-bold">{{ item.name.first }} {{ item.name.last }}</small>
                      <small v-if="item.school && item.school.name" class="d-block">{{ item.school.name.legal }} </small>
                      <small class="d-block">Invoice: #{{ item.invoiceNumber }} - {{ item.studentInvoiceId }} </small>


                      <template v-if="debug">
                        <b-badge v-for="(app) in item.applications.items" :key="app.id" variant="primary" class="mr-50">
                          <b-badge class="mr-50" :variant="getInstrumentVariant(app.applicationInstrumentId)" >
                            {{ app.applicationInstrumentId ? app.instrument.name : 'No Instrument' }}
                          </b-badge>
                          <b-badge :class="app.applicationSelectionId ? 'mr-50' : null" :variant="getEnsembleVariant(app.applicationSelectionId ? app.selection.ensembleId : null)">
                            {{ app.applicationSelectionId ? app.selection.ensemble.name : 'No Ensemble' }}
                          </b-badge>
                          <b-badge v-if="app.applicationSelectionId" :variant="getAcceptedVariant2(app.selection ? app.selection.accepted : null)">{{ app.selection ?  getAcceptedText(app.selection.accepted) : 'No Selection' }}</b-badge>
                        </b-badge>
                      </template>

                      <small v-if="item.errors.length" class="d-block text-danger mt-1">Errors: {{ item.errors }}</small>
                    </div>
                    <b-spinner v-if="item.saving" small></b-spinner>
                    <b-icon v-else :icon="item.icon" :variant="item.variant" class="mt-25"/>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-collapse>
          </vue-perfect-scrollbar>
        </div>

      </b-list-group-item>
    </b-list-group>
    <div class="d-flex justify-content-end">
      <b-button v-if="query.completed && creation.completed"
                block class="mt-50 shadow-none"
                variant="outline-white"
                :disabled="query.processing || creation.processing"
                @click="backToTable">
        Back to Table
      </b-button>
    </div>
  </b-alert>
</template>

<script>
import {API, graphqlOperation} from 'aws-amplify';
import {uuid} from 'vue-uuid';
import notify from '@/mixins/notify.mixin'
import {mask} from 'vue-the-mask'
import {listInvoices, listStudentsWithNoInvoiceAndHaveSelections, createInvoice, updateStudent} from './invoice-generator';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import acceptanceMixin from '@/mixins/acceptance.mixin';
import settingsMixin from '@/mixins/settings.mixin';


export default {
  name: 'InvoiceGenerator',
  directives: { mask },
  filters: {
    date(value) {
      return value
          ? new Intl.DateTimeFormat('en', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true }).format(new Date(value))
          : null
    },
    duration(milliseconds, percentage) {
      const remainingMilliseconds = milliseconds * (1 - percentage);
      const seconds = remainingMilliseconds / 1000;
      const minutes = seconds / 60;
      const hours = minutes / 60;

      if (hours >= 1) {
        const remainingMinutes = Math.floor(minutes % 60);
        return `${Math.floor(hours)} hours ${remainingMinutes} minutes`;
      }
      if (minutes >= 1) {
        return `${Math.floor(minutes)} minutes`;
      }
      return `${Math.floor(seconds)} seconds`;
    },
    clamp(number) {
      return parseFloat(number.toFixed(2));
    }
  },
  components: { VuePerfectScrollbar },
  mixins: [ notify, acceptanceMixin, settingsMixin ],
  props: {
    configuration: {
      type: Object,
      default: () => ({ isUndecided: false, hasAccepted: true, hasDeclined: false })
    },
    debug: {
      type: Boolean,
      default: false,
      description: 'If debug is true, the component will display the applications used to generate in the invoice in the details section.'
    },
  },
  data: () => ({
    generation: {
      processing: false,
      complete: false,
      expanded: true,
      status: 'Loading Students...',
      total: 0,
      processed: 0,
      expectedDuration: 0,
      students: {
        items: [],
        loading: false,
      },
      errors: [],
    },

    query: {
      total: 0,
      processed: 0,
      processing: false,
      completed: false,
      expectedDuration: 0,
      items: []
    },
    creation: {
      total: 0,
      processed: 0,
      success: 0,
      processing: false,
      completed: false,
      expectedDuration: 0,
    },
    details: {
      expanded: true,
    },
    errors: {
      items: [],
      expanded: false,
    }
  }),
  computed: {
    progressStatusText() {
      if (this.query.completed && this.creation.completed) { return 'Completed' }
      if (this.query.processing) { return 'Querying records' }
      if (this.creation.processing) { return `Inserting Invoices: ${this.creation.processed} / ${this.creation.total}` }
      return ''
    },
    queryProgressBarVariant() {
      const queryPercentage = this.queryProgressBarPercentage;
      if (queryPercentage > 0 && queryPercentage < 25) {
        return 'primary';
      }
      if (queryPercentage === 25) {
        return 'primary';
      }
      return 'dark';
    },
    queryProgressBarPercentage() {
      if (this.query.total === 0) { return 0 } // stops the value from being NaN
      return (this.query.processed / this.query.total) * 25;
    },
    creationProgressBarVariant() {
      const mutationPercentage = this.creationProgressBarPercentage;
      const maxPercentage = 75;
      if (mutationPercentage > 0 && mutationPercentage < maxPercentage) {
        return 'primary';
      }
      if (this.creation.completed && this.errors.items.length === 0) {
        return 'primary';
      }
      if (this.creation.completed && this.errors.items.length > 0 && this.errors.items.length < this.creation.total) {
        return 'warning';
      }
      if (this.creation.completed && this.errors.items.length === this.creation.total) {
        return 'danger';
      }
      return 'primary';
    },
    creationProgressBarPercentage() {
      const maxPercentage = 75;
      if(this.query.items.length === 0 && this.query.completed) { return 100; }
      if (this.creation.total === 0) { return 0; } // stops the value from being NaN
      return (this.creation.processed / this.creation.total) * maxPercentage;
    },
  },
  mounted() {
      this.generateInvoices()
  },
  methods: {
    /* eslint-disable no-await-in-loop, function-paren-newline */
    async listStudents() {
      const items = []
      let nextToken = null;
      const config = this.configuration;

      do {
        this.query.processing = true;
        this.query.total += 1;

        try {
          const response = await API.graphql(graphqlOperation(listStudentsWithNoInvoiceAndHaveSelections, {
            limit: 1000,
            nextToken: nextToken,
            filter: {
              createdAt: {
                between: [
                  this.settingsStore.app.current.year.start,
                  this.settingsStore.app.current.year.end
                ]
              }
            },
          }));
          items.push(...response.data.listStudents.items.filter(item =>
              item.studentInvoiceId === null
              && item.applications.items.some(application =>
                  application.applicationSelectionId !== null
                  && application.selection?.selectionEnsembleId !== null
              )
          ));
          nextToken = response.data.listStudents.nextToken;

          // Update list progress
          this.query.processed += 1;
        }
        catch (error) {
          console.error('Error fetching records:', error);
          break;
        }
      } while (nextToken);

      this.query.processing = false;
      this.query.completed = true;

      return items.filter(student =>
          student.applications.items.some(application =>
              ((config.isUndecided && application.selection?.accepted === null)
                  || (config.hasAccepted && application.selection?.accepted === true)
                  || (config.hasDeclined && application.selection?.accepted === false))
              && (config.instrumentId === null || application.applicationInstrumentId === config.instrumentId)
              && (config.ensembleId === null || application.selection?.selectionEnsembleId === config.ensembleId)
              && (config.districtId === null || student.school?.districtSchoolsId === config.districtId)
              && (config.schoolId === null || student.schoolID === config.schoolId)
          )
      )
      .map(item => ({
        ...item,
        studentInvoiceId: uuid.v4(),
        success: null,
        errors: [],
        variant: 'primary',
        icon: 'circle',
      }));
    },

    async listInvoices() {
      const items = []
      let nextToken = null;
      do {
        try {
          const response = await API.graphql(graphqlOperation(listInvoices, {
            limit: 1000,
            nextToken: nextToken,
            filter: {
              createdAt: {
                between: [
                  this.settingsStore.app.current.year.start,
                  this.settingsStore.app.current.year.end
                ]
              }
            },
          }));
          items.push(...response.data.listInvoices.items)
          nextToken = response.data.listInvoices.nextToken;
        }
        catch (error) {
          break;
        }
      } while (nextToken);
      return items
    },

    async generateInvoices() {
      this.$emit('start');
      this.reset()
      this.query.items = await this.listStudents()

      if (this.query.items.length === 0) {
          this.creation.total = 0
          this.creation.processed = 0
          this.creation.processing = false
          this.creation.completed = true
          this.$emit('complete');
          return
      }

      const batchSize = 1; // number of records to process in a batch
      const delayBetweenBatches = 1000; // delay between batches (in milliseconds)

      this.creation.processing = true
      this.creation.total = this.query.items.length
      this.creation.expectedDuration = Math.ceil(this.query.items.length / batchSize) * delayBetweenBatches;

      const invoiceNumber = await this.getStartingInvoiceNumber()
      this.query.items.forEach((item, index) => {
        item.invoiceNumber = invoiceNumber + index
      })

      await this.query.items.reduce(async (referencePoint, student, index) => {
        this.generation.status = 'Creating Invoices...'
        try {
          student.saving = true
          await referencePoint;
          this.creation.processed = index + 1

          try {
            const invoiceInput = { id: student.studentInvoiceId, invoiceStudentId: student.id, number: student.invoiceNumber }
            await API.graphql(graphqlOperation(createInvoice, { input: invoiceInput } ))
            await new Promise(resolve => setTimeout(resolve, 250))
          }
          catch(createInvoiceError) {
            student.errors.push(createInvoiceError)
            throw createInvoiceError
          }

          try {
            const studentInput = { id: student.id, studentInvoiceId: student.studentInvoiceId }
            await API.graphql(graphqlOperation(updateStudent, { input: studentInput } ))
            await new Promise(resolve => setTimeout(resolve, 250))
          }
          catch(updateStudentError) {
            updateStudentError.errors.forEach(error => {
                student.errors.push({ type: 'updateStudentError', error: error.message })
            })
            throw updateStudentError
          }

          student.success = true
          student.variant = 'success'
          student.icon = 'check-circle-fill'
          this.creation.success += 1
        }
        catch (studentOrInvoiceError) {
          console.error('studentOrInvoiceError', studentOrInvoiceError)
          student.success = false
          student.variant = 'danger'
          student.icon = 'x-circle-fill'
          this.errors.items.push({ student: student, error: studentOrInvoiceError})
        }
        finally {
          student.saving = false
        }
      }, Promise.resolve());

      this.creation.processing = false
      this.creation.completed = true
      this.$emit('complete');
    },

    async getStartingInvoiceNumber() {
      /**
       * maxInvoiceNumber: This represents the highest invoice number in the dataset. If the largest invoice number you have is 105, then maxInvoiceNumber will be 105.
       *
       * invoices.length: This is the total number of invoice records you fetched. If we fetched, 103 invoices, then invoices.length will be 103.
       *
       * Math.max(maxInvoiceNumber, invoices.length): Here, Math.max compares the two numbers maxInvoiceNumber and invoices.length. It will return whichever is larger.
       *
       * Scenario 1: If we have 103 invoices, and the highest invoice number among them is 105, it will return 105.
       * Scenario 2: If we have 110 invoices, and the highest invoice number among them is 105, it will return 110.
       * The rationale behind this comparison is:
       *
       * In Scenario 1, we've possibly deleted some invoices, but still want to generate the next invoice starting at 106.
       * In Scenario 2, there have been no deletions (or at least the deletions didn't affect the latest numbers), so we can start from where the count of the records indicates, which is 111.
       *
       * Once we have the larger of the two numbers (either the max invoice number or the total number of invoices), we increment it by 1 to ensure the new invoice number is unique and the next in sequence.
       * In summary, the return line ensures that we're always starting with a unique invoice number that's one greater than either the largest invoice number you have or the total count of the invoices, whichever is higher.
       */
      const invoices = await this.listInvoices()

      //Get the maximum invoice number from the fetched invoices
      const maxInvoiceNumber = Math.max(...invoices.map(invoice => invoice.number || 0));

      // Determine the starting invoice number
      return Math.max(maxInvoiceNumber, invoices.length) + 1;
    },

    backToTable() {
      this.$emit('close')
      this.reset()
    },
    reset() {
      this.query = {
        total: 0,
        processed: 0,
        processing: false,
        completed: false,
        expectedDuration: 0,
        items: []
      }
      this.creation = {
        total: 0,
        processed: 0,
        success: 0,
        processing: false,
        completed: false,
        expectedDuration: 0,
      }
      this.errors = {
        items: [],
        expanded: false
      }
      this.details = {
        expanded: false
      }
    },

    async copyErrorsToClipboard(items) {
      const errorMessages = JSON.stringify(items);
      try {
        await navigator.clipboard.writeText(errorMessages);
        this.$bvToast.toast('Copied to clipboard.', {
          title: 'Copied!',
          variant: 'success',
          autoHideDelay: 1000,
          appendToast: true,
          solid: true,
          toaster: 'b-toaster-bottom-right',
        });
      } catch (err) {
        console.error('Failed to copy to clipboard:', err);
        this.$bvToast.toast('Failed to copy to clipboard.', {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 1000,
          appendToast: true,
          solid: true,
          toaster: 'b-toaster-bottom-right',
        });
      }
    },
    downloadErrorsAsJson(items) {
      const dataStr = JSON.stringify(items);
      const blob = new Blob([dataStr], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `nyssma-invoice-generation-errors-${new Date().toISOString().replace(/:/g, '_')}.json`;
      link.click();
      URL.revokeObjectURL(url);
    },
    getEnsembleVariant(id) {
      if(id === null) return 'danger'
      if(this.configuration.ensembleId === null || this.configuration.ensembleId === id) return 'light-primary'
      return 'light-secondary'
    },
    getInstrumentVariant(id) {
        if(id === null) return 'danger'
        if(this.configuration.instrumentId === null || this.configuration.instrumentId === id) return 'light-primary'
        return 'light-secondary'
    },
    getAcceptedVariant2(value) {
        if(this.configuration.hasAccepted === true && value === true) return 'success'
        if(this.configuration.hasDeclined === true && value === false) return 'success'
        if(this.configuration.isUndecided === true && value === null) return 'success'
        return 'secondary'
    },
  }
}
</script>

<style lang="scss">
  .alert .list-group .list-group-item:hover {
    background-color: #fff;
  }
  .ps-changes {
    max-height: 300px;
  }
  .ps--active-y.ps-changes {
    padding-right: 1.5rem
  }
  .ps-processed {
    max-height: 50vh;
  }
  .ps--active-y.ps-processed {
    padding-right: 1.5rem
  }
  .alert .list-group .list-group-item:hover {
    background-color: #fff;
  }
  .percentage {
    width: 6ch;
    display: inline-flex;
    justify-content: end;
  }
  .badge-light-primary {
    background: #e4e5ed!important;
  }
  .badge-light-secondary {
    background: #fdf8e8!important;
  }
</style>
