<template>
	<b-modal :id="id" centered size="xl" title="Invoice Generation" no-close-on-backdrop footer-class="d-block px-1" body-class="p-1" @hidden="clear()">
		<!-- Content -->
		<template #default>
			<b-container class="py-1 px-50">

				<b-alert show variant="primary">
					<b-icon-info-circle class="mr-25"/> <small> <strong class="d-inline-block"> Invoice Generation Process and Bill Management</strong>
					<div>Please note that initiating this process will scan all applications to identify those that have been selected for an ensemble.
					If a student does not have an existing bill, a new one will be generated specifically for them. It is important to note that
					this process will not impact any bills from previous generations. If you wish to remove bills for students who have rejected
						all application selections, you will need to delete them manually.</div></small>
				</b-alert>

				<validation-observer ref="observer" v-slot="{ handleSubmit }" tag="div">
					<form ref="form" @submit.stop.prevent="handleSubmit(create)">
						<b-row>
              <b-col cols="12">
                <validation-provider v-slot="validationContext" rules="oneCheckboxRequired:@hasAccepted,@isUndecided,@hasDeclined">
                  <b-form-group class="mt-1 mb-0 font-small-3" :invalid-feedback="validationContext.errors[0]">
                    <template #label>
                      Acceptance (<span class="text-danger">required</span>)
                    </template>
                    <validation-provider name="hasAccepted">
                      <b-checkbox ref="hasAccepted" v-model="configuration.hasAccepted" switch inline :unchecked-value="false">Accepted</b-checkbox>
                    </validation-provider>
                    <validation-provider name="isUndecided">
                      <b-checkbox ref="isUndecided" v-model="configuration.isUndecided" switch inline :unchecked-value="false" >Undecided</b-checkbox>
                    </validation-provider>
                    <validation-provider name="hasDeclined">
                      <b-checkbox ref="hasDeclined" v-model="configuration.hasDeclined" switch inline :unchecked-value="false">Declined</b-checkbox>
                    </validation-provider>
                    <div v-if="validationContext.errors.length" class="text-danger font-small-3 pt-50">
                      {{ validationContext.errors[0] }}
                    </div>
                  </b-form-group>
                </validation-provider>
                <hr class="mt-2">
              </b-col>
							<b-col cols="6">
								<b-form-group label="Ensemble" class="mt-1 mb-0 font-small-3">
									<v-select v-model="configuration.ensembleId"
														:loading="options.ensembles.loading"
														:options="ensembles" label="name"
														:reduce="option => option.id"
									/>
								</b-form-group>
							</b-col>
              <b-col cols="6">
                <b-form-group label="Instrument" class="mt-1 mb-0 font-small-3">
                  <v-select v-model="configuration.instrumentId"
														:loading="options.instruments.loading"
														:options="instruments" label="name"
														:reduce="option => option.id"
									/>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="District" class="mt-1 mb-0 font-small-3">
                  <v-select v-model="configuration.districtId"
                            :loading="options.districts.loading"
                            :options="districts" label="name"
                            :reduce="option => option.id"
                            :filter="filterSchools">
                    <template #option="{ name }">
                      {{ name.legal }}
                    </template>
                    <template #selected-option="{ name }">
                      {{ name.legal }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="School" class="mt-1 mb-0 font-small-3">
                  <v-select v-model="configuration.schoolId"
                            :loading="options.schools.loading"
                            :options="schools" label="name"
                            :reduce="option => option.id"
                            :filter="filterSchools">
                    <template #option="{ name }">
                      {{ name.legal }}
                    </template>
                    <template #selected-option="{ name }">
                      {{ name.legal }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
						</b-row>
					</form>
				</validation-observer>
			</b-container>
		</template>

		<!-- Footer -->
		<template #modal-footer>
			<b-row>
				<b-col align-self="center" class="px-50 text-right">
					<b-button type="reset" class="mr-1" @click="clear()">Clear</b-button>
					<b-button type="submit" variant="primary" @click="$refs.observer.handleSubmit(create)">Generate</b-button>
				</b-col>
			</b-row>
		</template>
	</b-modal>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import vSelect from 'vue-select'
import notify from '@/mixins/notify.mixin'
import { listEnsembles, listInstruments, listDistricts, listSchools } from './invoice-generation-modal';
import { extend } from 'vee-validate';
import Fuse from 'fuse.js';

extend('oneCheckboxRequired', {
	validate: (value, { hasAccepted, isUndecided, hasDeclined }) => (hasAccepted || isUndecided || hasDeclined),
  params: ['hasAccepted', 'isUndecided', 'hasDeclined'],
	message: 'At least one acceptance status must be checked'
});

export default {
    name: 'InvoiceGenerationModal',
    components: {
        vSelect
    },
    mixins: [ notify ],
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
					configuration: {
						isUndecided: false,
						hasAccepted: true,
						hasDeclined: false,
            ensembleId: null,
						instrumentId: null,
            districtId: null,
            schoolId: null,
					},
					selected: [],
					options: {
            types: [ 'Undecided', 'Accepted', 'Declined' ],
            ensembles: {
              items: [],
              loading: false,
              loaded: false
            },
            instruments: {
              items: [],
              loading: false,
              loaded: false
            },
            districts: {
              items: [],
              loading: false,
              loaded: false
            },
            schools: {
              items: [],
              loading: false,
              loaded: false
            },
					},
					state: {
							redirect: false
					},
        }
    },
    computed: {
			ensembles() {
				if(!this.options.ensembles.loaded) {
					this.listEnsembles()
					return []
				}
				return this.options.ensembles.items
			},
			instruments() {
				if(!this.options.instruments.loaded) {
					this.listInstruments()
					return []
				}
				return this.options.instruments.items
			},
      districts() {
        if(!this.options.districts.loaded) {
          this.listDistricts()
          return []
        }
        return this.options.districts.items
      },
      schools() {
        if(!this.options.schools.loaded) {
          this.listSchools()
          return []
        }
        if(this.configuration.districtId === null) {
          return this.options.schools.items
        }
        return this.options.schools.items.filter(school => school.districtSchoolsId === this.configuration.districtId)
      }
    },
    watch: {
      'configuration.districtId'(value) {
        if(value === null && this.configuration.schoolId !== null) {
          this.configuration.schoolId = null
        }
      },
    },
    methods: {
			/* eslint-disable no-await-in-loop, function-paren-newline */
			async create() {
				this.$emit('generate', this.configuration)
				this.$root.$emit('bv::toggle::modal', this.id)
				this.notify({ title: 'Generating', text: 'Invoice Generation Started', icon: 'fas fa-gear', variant: 'success' });
			},
			async listEnsembles() {
				const items = []
				let nextToken = null;
				do {
					this.options.ensembles.loading = true
					try {
						const response = await API.graphql(graphqlOperation(listEnsembles, { limit: 1000, nextToken: nextToken }));
						items.push(...response.data.listEnsembles.items)
						nextToken = response.data.listEnsembles.nextToken;
					}
					catch (error) {
						break;
					}
				} while (nextToken);

				this.options.ensembles.items = items.sort((a, b) => a.name.localeCompare(b.name))
				this.options.ensembles.loading = false
				this.options.ensembles.loaded = true
			},
			async listInstruments() {
				const items = []
				let nextToken = null;
				do {
					this.options.instruments.loading = true
					try {
						const response = await API.graphql(graphqlOperation(listInstruments, { limit: 1000, nextToken: nextToken }));
						items.push(...response.data.listInstruments.items)
						nextToken = response.data.listInstruments.nextToken;
					}
					catch (error) {
						break;
					}
				} while (nextToken);

				this.options.instruments.items = items.sort((a, b) => a.name.localeCompare(b.name))
				this.options.instruments.loading = false
				this.options.instruments.loaded = true
			},
      async listDistricts() {
        const items = []
        let nextToken = null;
        do {
          this.options.districts.loading = true
          try {
            const response = await API.graphql(graphqlOperation(listDistricts, { limit: 1000, nextToken: nextToken }));
            items.push(...response.data.listDistricts.items)
            nextToken = response.data.listDistricts.nextToken;
          }
          catch (error) {
            break;
          }
        } while (nextToken);

        this.options.districts.items = items.sort((a, b) => a.name?.legal.localeCompare(b.name?.legal))
        this.options.districts.loading = false
        this.options.districts.loaded = true
      },
      async listSchools() {
        const items = []
        let nextToken = null;
        do {
          this.options.schools.loading = true
          try {
            const response = await API.graphql(graphqlOperation(listSchools, { limit: 1000, nextToken: nextToken }));
            items.push(...response.data.listSchools.items)
            nextToken = response.data.listSchools.nextToken;
          }
          catch (error) {
            break;
          }
        } while (nextToken);

        this.options.schools.items = items.sort((a, b) => a.name?.legal.localeCompare(b.name?.legal))
        this.options.schools.loading = false
        this.options.schools.loaded = true
      },
      filterSchools(options, search) {
        const fuse = new Fuse(options, {
          keys: ['name.legal', 'name.popular'],
          threshold: 0.2,
          shouldSort: true,
        })
        return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
      },
			clear() {

			},
			getValidationState({ dirty, validated, valid = null }) {
					return dirty || validated ? valid : null;
			},
    }
}
</script>

<style scoped>

</style>
