// eslint-disable-next-line import/prefer-default-export
export const getSetting = /* GraphQL */ `
    query GetSetting($key: String!) {
        getSetting(key: $key) {
            key
            value
            description
            createdAt
            updatedAt
        }
    }
`;

export const getInvoice = /* GraphQL */ `
    query GetInvoice($id: ID!) {
        getInvoice(id: $id) {
            id
            number
            amount
            dueAt
            payments {
                items {
                    type
                    amount
                    check
                    received
                    returned
                    id
                    invoicePaymentsId
                }
                nextToken
            }
            student {
                id
                name {
                    first
                    last
                }
                address {
                    line1
                    line2
                    city
                    county
                    state
                    zip
                }
                phone {
                    type
                    number
                    ext
                }
                email {
                    type
                    address
                }
                school {
                    id
                    name {
                        legal
                        popular
                    }
                    address {
                        line1
                        line2
                        city
                        county
                        state
                        zip
                    }
                }
                applications {
                    items {
                        id
                        instrument {
                            name
                        }
                        selection {
                            id
                            ensemble {
                                name
                            }
                            part
                            accepted
                        }
                    }
                }
            }
            memo
            duplicates
            createdAt
            updatedAt
            invoiceStudentId
        }
    }
`;
export const updateInvoice = /* GraphQL */ `
    mutation UpdateInvoice(
        $input: UpdateInvoiceInput!
        $condition: ModelInvoiceConditionInput
    ) {
        updateInvoice(input: $input, condition: $condition) {
            id
            number
            amount
            dueAt
            payments {
                items {
                    type
                    amount
                    check
                    received
                    returned
                    id
                    createdAt
                    updatedAt
                    invoicePaymentsId
                }
                nextToken
            }
            student {
                id
                name {
                    first
                    last
                    preferred
                }
                dob
                sex
                gender
                address {
                    line1
                    line2
                    city
                    county
                    state
                    zip
                }
                phone {
                    type
                    number
                    ext
                }
                email {
                    type
                    address
                }
                grade
                school {
                    id
                    beds
                    slug
                    createdAt
                    updatedAt
                    districtSchoolsId
                    schoolZoneId
                }
                applications {
                    nextToken
                }
                invoice {
                    id
                    number
                    amount
                    dueAt
                    memo
                    duplicates
                    createdAt
                    updatedAt
                    invoiceStudentId
                }
                createdAt
                updatedAt
                schoolID
                studentInvoiceId
            }
            memo
            duplicates
            createdAt
            updatedAt
            invoiceStudentId
        }
    }
`;


export const getPayment = /* GraphQL */ `
    query GetPayment($id: ID!) {
        getPayment(id: $id) {
            id
            type
            amount
            check
            received
            returned
            invoicePaymentsId
        }
    }
`;

export const updatePayment = /* GraphQL */ `
    mutation UpdatePayment(
        $input: UpdatePaymentInput!
        $condition: ModelPaymentConditionInput
    ) {
        updatePayment(input: $input, condition: $condition) {
            type
            amount
            check
            received
            returned
            invoice {
                id
                payments {
                    nextToken
                }
                student {
                    id
                    dob
                    sex
                    gender
                    grade
                    createdAt
                    updatedAt
                    schoolID
                    studentInvoiceId
                }
                memo
                duplicates
                createdAt
                updatedAt
                invoiceStudentId
            }
            id
            createdAt
            updatedAt
            invoicePaymentsId
        }
    }
`;
export const deletePayment = /* GraphQL */ `
    mutation DeletePayment(
        $input: DeletePaymentInput!
        $condition: ModelPaymentConditionInput
    ) {
        deletePayment(input: $input, condition: $condition) {
            type
            amount
            check
            received
            returned
            invoice {
                id
                payments {
                    nextToken
                }
                student {
                    id
                    dob
                    sex
                    gender
                    grade
                    createdAt
                    updatedAt
                    schoolID
                    studentInvoiceId
                }
                memo
                duplicates
                createdAt
                updatedAt
                invoiceStudentId
            }
            id
            createdAt
            updatedAt
            invoicePaymentsId
        }
    }
`;


export const onCreatePayment = /* GraphQL */ `
    subscription OnCreatePayment {
        onCreatePayment {
            type
            amount
            check
            received
            returned
            id
            invoicePaymentsId
        }
    }
`;
export const onUpdatePayment = /* GraphQL */ `
    subscription OnUpdatePayment {
        onUpdatePayment {
            type
            amount
            check
            received
            returned
            id
            invoicePaymentsId
        }
    }
`;
export const onDeletePayment = /* GraphQL */ `
    subscription OnDeletePayment {
        onDeletePayment {
            id
        }
    }
`;
