// eslint-disable-next-line import/prefer-default-export
export const listInvoices = /* GraphQL */ `
    query ListInvoices(
        $id: ID
        $filter: ModelInvoiceFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listInvoices(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                number
                payments {
                    items {
                        id
                        type
                        amount
                        received
                        returned
                    }
                }
                student {
                    id
                    name {
                        first
                        last
                    }
                    school {
                        id
                        name {
                            legal
                        }
                    }
                    applications {
                        items {
                            id
                            applicationInstrumentId
                            instrument {
                                id
                                name
                            }
                            selection {
                                selectionEnsembleId
                                ensemble {
                                    id
                                    name
                                }
                                part
                                accepted
                            }
                        }
                    }
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const listSelections = /* GraphQL */ `
    query ListSelections(
        $id: ID
        $filter: ModelSelectionFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSelections(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                selectionEnsembleId
                accepted
                application {
                    id
                    applicationInstrumentId
                    student {
                        id
                        name {
                            first
                            last
                        }
                        school {
                            name {
                                legal
                            }
                        }
                        invoice {
                            id
                            payments {
                                items {
                                    id
                                    type
                                    amount
                                    received
                                    returned
                                }
                            }
                        }
                    }
                }
            }
            nextToken
        }
    }
`;


export const listStudentsWithNoInvoiceAndHaveSelections = /* GraphQL */ `
    query ListStudents(
        $id: ID
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listStudents(
            id: $id
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                schoolID,
                studentInvoiceId
                name {
                    first
                    last
                }
                applications {
                    items {
                        id
                        applicationInstrumentId
                        applicationSelectionId
                        instrument {
                            name
                        }
                        selection {
                            selectionEnsembleId
                            ensemble {
                                name
                            }
                            part
                            accepted
                        }
                    }
                }
            }
            nextToken
        }
    }
`;

export const createInvoice = /* GraphQL */ `
    mutation CreateInvoice(
        $input: CreateInvoiceInput!
        $condition: ModelInvoiceConditionInput
    ) {
        createInvoice(input: $input, condition: $condition) {
            id
            invoiceStudentId
        }
    }
`;


export const updateStudent = /* GraphQL */ `
    mutation UpdateStudent(
        $input: UpdateStudentInput!
        $condition: ModelStudentConditionInput
    ) {
        updateStudent(input: $input, condition: $condition) {
            id
            studentInvoiceId
        }
    }
`;

export const deleteInvoice = /* GraphQL */ `
    mutation DeleteInvoice(
        $input: DeleteInvoiceInput!
        $condition: ModelInvoiceConditionInput
    ) {
        deleteInvoice(input: $input, condition: $condition) {
            id
            invoiceStudentId
        }
    }
`;
export const deletePayment = /* GraphQL */ `
    mutation DeletePayment(
        $input: DeletePaymentInput!
        $condition: ModelPaymentConditionInput
    ) {
        deletePayment(input: $input, condition: $condition) {
            id
        }
    }
`;
