<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs>
      <b-breadcrumb-item :text="`Management - ${$store.state.settings.app.current.title}`"/>
      <b-breadcrumb-item active text="Invoices"/>
    </template>


    <template #dropdown-options="{ }">
      <b-dropdown-item @click="refresh">
        <font-awesome-icon icon="fa-solid fa-rotate-right"></font-awesome-icon>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #actions="{ }">
      <b-button v-b-modal="'invoice-generation-modal'" size="sm" variant="primary" :disabled="generation.processing">
        <font-awesome-icon icon="fa-solid fa-plus"></font-awesome-icon>
        <span class="align-middle ml-50">Generate Invoices</span>
      </b-button>
      
      <invoice-generation-modal id="invoice-generation-modal" @generate="generateInvoices" />
    </template>

    <template #content="{state}">
      <table-layout ref="table-layout"
                    :items="table.items"
                    :fields="table.fields"
                    :filters="table.filters" :filters-options="{ visible: true, collapsed: false, cols: 3 }"
                    :sorting="table.sorting"
                    :func-delete="deleteInvoice"
                    :loading="table.loading"
                    :visible="table.visible"
                    :export-exclude-fields="[
                        'id',
                        'student.id',
                        'student.school.id',
                        'status',
                      ]"
                    @mounted="table = $event"
                    @updated="table = $event">

        <!-- Overlay -->
        <template #overlay>
          <overlay-loading :items="[
            { state: setting === null, desc: 'Loading Settings' },
            { state: table.loading, desc: 'Loading Invoices', loaded: table.items.length },
            { state: state.loading, desc: 'Rendering Template'},
          ]" />
        </template>

        <!-- Filters -->
        <template #filters>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Status" label-for="status-input">
                <v-select id="status-input"
                          v-model="table.filters.status.value"
                          :options="statusLabels"
                          :reduce="option => option.value"
                          :searchable="false"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100 font-small-3"/>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Accepted" label-for="accepted-input">
                <v-select id="accepted-input"
                          v-model="table.filters.accepted.value"
                          :options="options.accepted"
                          :reduce="option => option.value" label="label"
                          :searchable="false"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100 font-small-3"/>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="School" label-for="school-input">
                <v-select id="school-input"
                          v-model="table.filters.school.value"
                          :options="options.schools.items"
                          :loading="options.schools.loading"
                          :reduce="option => option.id" label="name"
                          :filter="filterSchools"
                          :searchable="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100 font-small-3">
                  <template #option="{ name }">
                    {{ name.legal }}
                  </template>
                  <template #selected-option="{ name }">
                    {{ name.legal }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Ensemble" label-for="ensemble-input">
                <v-select id="ensemble-input"
                          v-model="table.filters.ensemble.value"
                          :options="options.ensembles.items"
                          :loading="options.ensembles.loading"
                          :reduce="option => option.id" label="name"
                          :searchable="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100 font-small-3"/>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Instrument" label-for="instrument-input">
                <v-select id="instrument-input"
                          v-model="table.filters.instrument.value"
                          :options="options.instruments.items"
                          :loading="options.instruments.loading"
                          :reduce="option => option.id" label="name"
                          :searchable="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100 font-small-3"/>
              </b-form-group>
            </b-col>

          </b-row>
        </template>

        <!-- Column: Student -->
        <template #cell(student)="{data}">
          <b-media vertical-align="center" no-body>
            <b-media-aside>
              <b-avatar button
                        variant="primary"
                        size="2.5em"
                        badge-variant="primary" badge-offset="-2px"
                        @click="data.toggleDetails">
                <font-awesome-icon :icon="icon"></font-awesome-icon>
                <template #badge>
                  <b-icon :icon="data.item._showDetails === true ? 'chevron-up' : 'chevron-down'" />
                </template>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="align-self-center">
              <b-link :to="{ name: 'management-invoice', params: { id: data.item.id } }" class="font-weight-bold d-block text-nowrap">
                {{ data.item.student.name.full }}
              </b-link>

              <span v-if="hasValue(data, 'item.student.school.name.legal')" class="d-block font-small-3">
                {{ data.item.student.school.name.legal }}
              </span>
              <span v-else class="d-block font-small-3 text-danger">No School</span>
            </b-media-body>
          </b-media>
        </template>



        <!-- Column: Status -->
        <template #cell(status)="{data}">
          <b-badge v-for="status in data.item.status" :key="status.text" pill :variant="status.variant" class="text-capitalize mr-50">{{ status.text }}</b-badge>
        </template>

        <!-- Column: Row Options -->
        <template #cell(row-options)="{data}">
          <b-dropdown-item :to="{ name: 'management-invoice', params: { id: data.item.id } }">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Details</span>
          </b-dropdown-item>
          <can do="delete" on="management-invoice">
            <b-dropdown-item class="table-row-option-delete"
                             @click="$refs.layout.confirmDelete(data.item, deleteInvoice, cascadeConfirmDeleteOptions)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </can>
        </template>
      </table-layout>

      <template v-if="!table.visible">
        <invoice-generator :key="generation.key"
                           :configuration="generation.config"
                           :debug="state.debug"
                           @start="generation.processing = true"
                           @complete="generation.processing = false"
                           @close="backToTable"/>
      </template>
    </template>

    <template #debug>
      <b-row>
        <b-col cols="4">
          <debug title="Table" :collapsed="true">{{ table }}</debug>
        </b-col>
        <b-col cols="4">
          <debug title="Settings" :collapsed="true">{{ setting }}</debug>
        </b-col>
        <b-col cols="4">
          <debug title="Options" :collapsed="true">{{ options }}</debug>
        </b-col>
      </b-row>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import TableLayout from '@/components/TableLayout.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';
import InvoiceGenerationModal from '@/views/management/billing/InvoiceGenerationModal.vue';
import InvoiceGenerator from '@/views/management/billing/InvoiceGenerator.vue';
import avatar from '@/mixins/avatar.mixin';
import notify from '@/mixins/notify.mixin';
import print from '@/mixins/print.mixin';
import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import {uuid} from 'vue-uuid';
import Fuse from 'fuse.js';
import { API, graphqlOperation } from 'aws-amplify';
import {getSetting} from '@/views/management/billing/invoice';
import {listInvoices, updateStudent, deleteInvoice, deletePayment} from './invoices';
import { cascadeDeleteInvoice, cascadeConfirmDeleteOptions} from '@/graphql/cascade/invoice';
import lodashMixin from '@/mixins/lodash.mixin';
import settingsMixin from '@/mixins/settings.mixin';

export default {
  name: 'Invoices',
  components: {
    PageLayout,
    TableLayout,
    InvoiceGenerator,
    InvoiceGenerationModal,
    OverlayLoading,
    vSelect,
    VuePerfectScrollbar
  },
  filters: {
      date(value) {
          return value
              ? new Intl.DateTimeFormat('en', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true }).format(new Date(value))
              : null
      },
      duration(milliseconds, percentage) {
          const remainingMilliseconds = milliseconds * (1 - percentage);
          const seconds = remainingMilliseconds / 1000;
          const minutes = seconds / 60;
          const hours = minutes / 60;

          if (hours >= 1) {
              const remainingMinutes = Math.floor(minutes % 60);
              return `${Math.floor(hours)} hours ${remainingMinutes} minutes`;
          }
          if (minutes >= 1) {
              return `${Math.floor(minutes)} minutes`;
          }
          return `${Math.floor(seconds)} seconds`;
      },
      clamp(number) {
          return parseFloat(number.toFixed(2));
      }
  },
  mixins: [avatar, notify, print, lodashMixin, settingsMixin],
  data() {
    return {
      table: {
        fields: [
          {
            key: 'student',
            subKeys: ['student.name.full', 'student.school.name.legal'],
            label: 'Name',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'number',
            label: 'Invoice #',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'payments.items.length',
            label: 'Payments',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'createdAt',
            label: 'Created',
            sortable: true,
            filterable: true,
            visible: false,
            tdClass: 'align-middle',
            formatter: (value, key, item) => this.$options.filters.date(item.createdAt)
          },
          {
            key: 'updatedAt',
            label: 'Modified',
            sortable: true,
            filterable: true,
            visible: false,
            tdClass: 'align-middle',
            formatter: (value, key, item) => this.$options.filters.date(item.updatedAt)
          },
          {
            key: 'status',
            subKeys: ['statusLabels'],
            label: 'Status',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'row-options',
            label: '',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: ['align-middle', 'table-row-options']
          },
        ],
        filters: {
          status: { key: 'statusLabels', value: null },
          accepted: { key: 'student.applications.items.selection.accepted', value: null },
          school: { key: 'student.school.id', value: null },
          ensemble: { key: 'student.applications.items.selection.selectionEnsembleId', value: null },
          instrument: { key: 'student.applications.items.applicationInstrumentId', value: null }
        },
        sorting: {
          by: 'number',
          desc: false
        },
        items: [],
        loading: true,
        visible: true,
      },
      options: {
        accepted: [
          { label: 'Accepted', value: 'true' },
          { label: 'Declined', value: 'false' },
          { label: 'Undecided', value: 'null' },
        ],
        schools: {
          items: [],
          loading: true
        },
        ensembles: {
          items: [],
          loading: true
        },
        instruments: {
          items: [],
          loading: true
        },
      },
      generation: {
        key: uuid.v4(),
        config: null,
        processing: false,
      },
      setting: null,
      icon: 'fa-file-invoice-dollar',
      debug: {},
      cascadeConfirmDeleteOptions
    }
  },
  computed: {
    statusLabels() {
      return [
        { label: 'Paid', value: 'paid' },
        { label: 'Underpaid', value: 'underpaid' },
        { label: 'Overpaid', value: 'overpaid' },
        { label: 'Pending', value: 'pending' }
      ]
    },
  },
  async mounted() {
    await this.getSetting();
    await this.listInvoices();
    this.$refs.layout.state.loading = false
  },
  methods: {
    /** Settings **/
    async getSetting() {
      const response = await API.graphql(graphqlOperation(getSetting, { key: 'billing' }));
      this.setting = response.data.getSetting
      if(this.setting) {
        this.setting = JSON.parse(this.setting.value)
      }
    },

    /** Invoice **/
    async listInvoices(nextToken, pagedInvoices) {
      this.table.loading = true
      const invoices = pagedInvoices || []

      const input = {
        limit: 1000,
        nextToken: nextToken,
        filter: {
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start,
              this.settingsStore.app.current.year.end
            ]
          }
        },
      }
      const response = await API.graphql(graphqlOperation(listInvoices, input));
      invoices.push(...response.data.listInvoices.items);

      if (response.data.listInvoices.nextToken) {
        await this.listInvoices(response.data.listInvoices.nextToken, invoices)
      }
      else {
        this.table.items = invoices.map((invoice) => ({ ...invoice, status: this.getStatuses(invoice), statusLabels: this.getStatuses(invoice).map(status => status.text) }))

        //Update Accepted null to 'null' for fuse filtering/searching
        this.table.items.forEach(item => {
          if(item.student.name) {
            item.student.name.full = `${item.student.name.first} ${item.student.name.last}`
          }
          if(item.student?.applications?.items) {
            item.student.applications.items.forEach(application => {
              if(application.selection && application.selection.accepted === null) {
                application.selection.accepted = 'null'
              }
            })
          }
        })
        this.table.loading = false

        // Get unique schools for Options
        this.options.schools.loading = true
        this.options.schools.items = Array.from(this.table.items.reduce((acc, item) => {
          if (item.student?.school) {
            acc.set(item.student.school.id, { id: item.student.school.id, name: item.student.school.name });
          }
          return acc;
        }, new Map()).values()).sort((a, b) => a.name.legal.localeCompare(b.name.legal));
        if(!this.options.schools.items.some(item => item.id === this.table.filters.school.value)) {
          this.table.filters.school.value = null
        }
        this.options.schools.loading = false


        // Get unique ensembles for Options
        this.options.ensembles.loading = true
        this.options.ensembles.items = Array.from(this.table.items.reduce((acc, item) => {
          if (item.student?.applications?.items) {
            item.student.applications.items.forEach(application => {
              if (application.selection?.selectionEnsembleId) {
                acc.set(application.selection.selectionEnsembleId, application.selection.ensemble);
              }
            });
          }
          return acc;
        }, new Map()).values()).sort((a, b) => a.name.localeCompare(b.name));
        if(!this.options.ensembles.items.some(item => item.id === this.table.filters.ensemble.value)) {
          this.table.filters.ensemble.value = null
        }
        this.options.ensembles.loading = false


        // Get unique instruments for Options
        this.options.instruments.loading = true
        this.options.instruments.items = Array.from(this.table.items.reduce((acc, item) => {
          if (item.student?.applications?.items) {
            item.student.applications.items.forEach(application => {
              if (application?.instrument?.id) {
                // Use instrument.id as the key and the whole instrument object as the value
                acc.set(application.instrument.id, application.instrument);
              }
            });
          }
          return acc;
        }, new Map()).values()).sort((a, b) => a.name.localeCompare(b.name));
        if(!this.options.instruments.items.some(item => item.id === this.table.filters.instrument.value)) {
          this.table.filters.instrument.value = null
        }
        this.options.instruments.loading = false
      }
    },
    async deleteInvoice(invoice, swalCallback) {
      try {
        this.table.loading = true
        await this.cascadeDeleteInvoice(invoice.id, swalCallback)
        this.table.items = this.table.items.filter(item => item.id !== invoice.id);
        this.notify({ title: 'Success', text: 'Invoice was successfully deleted', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Invoice failed to delete', icon: this.icon, variant: 'danger'});
        throw error //for Swal
      }
      finally {
        this.table.loading = false
      }
    },
    cascadeDeleteInvoice,

    /** Generate **/
    generateInvoices(config) {
      this.generation.key = uuid.v4()
      this.generation.config = config
      this.table.visible = false
    },

    /** UI **/
    async refresh() {
      this.table.loading = true
      this.options.instruments.loading = true
      this.options.ensembles.loading = true
      this.options.schools.loading = true
      await this.listInvoices()
    },
    getSaveIcon(generation) {
        if(generation.complete) {
            if(generation.students.items.every(item => item.success)) return 'check-circle-fill'
            if(generation.students.items.every(item => item.error)) return 'x-circle-fill'
            return 'slash-circle-fill'
        }
        return 'circle'
    },
    getSaveVariant(save) {
        if(save.complete) {
            if(save.students.items.every(item => item.success)) return 'success'
            if(save.students.items.every(item => item.error)) return 'danger'
            return 'warning'
        }
        return 'primary'
    },
    getSaveClass() {
        if(this.generation.processing || this.generation.complete) {
            if(this.$refs['processed-scrollbar']?.ps?.scrollbarYActive === true) {
                return 'mr-2'
            }
        }
        return ''
    },
    backToTable() {
        this.table.visible = true
        this.refresh()
    },
    getStatuses(invoice) {
      const statuses = []
      if(invoice) {
        const totalPaid = this.totalPaid(invoice.payments.items)
        const totalDue = this.totalDue()

        if(totalPaid > totalDue) {
          statuses.push({ text: 'overpaid', variant: 'danger' })
        }
        else if(totalPaid === totalDue) {
          statuses.push({ text: 'paid', variant: 'success' })
        }
        else {
          statuses.push({ text: 'underpaid', variant: 'secondary' })
        }
        if(this.totalPending(invoice.payments.items) > 0) {
          statuses.push({ text: 'pending', variant: 'primary' })
        }
        if(this.shouldRefund(invoice)) {
          statuses.push({ text: 'refund', variant: 'danger' })
        }
      }
      return statuses
    },

    /** Util **/
    totalCost() {
      return this.setting.fees.nyssma + this.setting.fees.hotel
    },
    totalPaid(payments = []) {
      let paid = 0
      payments.filter(payment => payment.received && !payment.returned).forEach(payment => {
        paid += payment.amount
      })
      return paid
    },
    totalPending(payments = []) {
      let pending = 0
      payments.filter(payment => !payment.received).forEach(payment => {
        pending += payment.amount
      })
      return pending
    },
    totalReturns(payments = []) {
      let returns = 0
      if(this.invoice) {
        payments.items.filter(payment => payment.returned).forEach(payment => {
          returns += payment.amount
        })
      }
      return returns
    },
    totalDue() {
      return this.totalCost() - this.totalPaid()
    },
    shouldRefund(invoice) {
      if(invoice.student?.applications?.items.every(app => app?.selection?.accepted !== true)) {
        if(invoice.payments?.items?.length > 0 && invoice.payments?.items.some(payment => payment?.returned === null)) {
            return true
        }
      }
      return false
    },

    /** Filters **/
    filterSchools(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name.legal', 'name.popular'],
        threshold: 0.2,
        shouldSort: true,
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },
  }
}
</script>

<style lang="scss">
.alert .list-group .list-group-item:hover {
  background-color: #fff;
}
.ps-changes {
  max-height: 300px;
}
.ps--active-y.ps-changes {
  padding-right: 1.5rem
}
.ps-processed {
  max-height: 50vh;
}
.ps--active-y.ps-processed {
  padding-right: 1.5rem
}
.alert .list-group .list-group-item:hover {
  background-color: #fff;
}
.percentage {
  width: 6ch;
  display: inline-flex;
  justify-content: end;
}
</style>
