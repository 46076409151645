export const listEnsembles = /* GraphQL */ `
    query ListEnsembles(
        $id: ID
        $filter: ModelEnsembleFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listEnsembles(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
            }
            nextToken
        }
    }
`;

export const listInstruments = /* GraphQL */ `
    query ListInstruments(
        $id: ID
        $filter: ModelInstrumentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listInstruments(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                order
            }
            nextToken
        }
    }
`;

export const listDistricts = /* GraphQL */ `
    query ListDistricts(
        $id: ID
        $filter: ModelDistrictFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listDistricts(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    legal
                    popular
                }
            }
            nextToken
        }
    }
`;

export const listSchools = /* GraphQL */ `
    query ListSchools(
        $id: ID
        $filter: ModelSchoolFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSchools(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                districtSchoolsId
                name {
                    legal
                    popular
                }
            }
            nextToken
        }
    }
`;
