export const listStudentsWithNoInvoiceAndHaveSelections = /* GraphQL */ `
    query ListStudents(
        $id: ID
        $limit: Int
        $filter: ModelStudentFilterInput
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listStudents(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                schoolID,
                studentInvoiceId
                name {
                    first
                    last
                }
                school {
                    id
                    districtSchoolsId
                    name {
                        legal
                    }
                }
                applications {
                    items {
                        id
                        applicationInstrumentId
                        applicationSelectionId
                        instrument {
                            name
                        }
                        selection {
                            selectionEnsembleId
                            ensemble {
                                name
                            }
                            part
                            accepted
                        }
                    }
                }
            }
            nextToken
        }
    }
`;

export const listInvoices = /* GraphQL */ `
    query ListInvoices(
        $id: ID
        $filter: ModelInvoiceFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listInvoices(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                number
            }
            nextToken
        }
    }
`;

export const createInvoice = /* GraphQL */ `
    mutation CreateInvoice(
        $input: CreateInvoiceInput!
        $condition: ModelInvoiceConditionInput
    ) {
        createInvoice(input: $input, condition: $condition) {
            id
            invoiceStudentId
        }
    }
`;


export const updateStudent = /* GraphQL */ `
    mutation UpdateStudent(
        $input: UpdateStudentInput!
        $condition: ModelStudentConditionInput
    ) {
        updateStudent(input: $input, condition: $condition) {
            id
            studentInvoiceId
        }
    }
`;

